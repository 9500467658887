<template>
    <div class="flex items-center px-6 py-5">
        <div class="flex w-full">
            <div class="ml-auto">
                <button @click="addServiceRow" class="mr-6">+ Add another service</button>
                <button @click="save" class="btn-primary ml-4">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Buttons',
    methods: {
        addServiceRow() {
            this.$emit('addNewRow')
        },
        save() {
            this.$emit('save')
        }
    }
};
</script>
